
export const privUsers: string[] = [
  "caleb.payan@cetys.edu.mx",
  "vegetta777@cetys.edu.mx",
  'juez001@cetys.mx',
  'juez002@cetys.mx',
  'juez003@cetys.mx',
  'juez004@cetys.mx',
  'juez005@cetys.mx',
  'juez006@cetys.mx',
  'juez007@cetys.mx',
  'juez008@cetys.mx',
  'juez009@cetys.mx',
  'juez010@cetys.mx',
  'juez011@cetys.mx',
  'juez012@cetys.mx',
  'juez013@cetys.mx',
  'juez014@cetys.mx',
  'juez015@cetys.mx',
  'juez016@cetys.mx',
  'juez017@cetys.mx',
  'juez018@cetys.mx',
  'juez019@cetys.mx',
  'juez020@cetys.mx',
  'juez021@cetys.mx',
  'juez022@cetys.mx',
  'juez023@cetys.mx',
  'juez024@cetys.mx',
  'juez025@cetys.mx',
  'juez026@cetys.mx',
  'juez027@cetys.mx',
  'juez028@cetys.mx',
  'juez029@cetys.mx',
  'juez030@cetys.mx',
  'juez031@cetys.mx',
  'juez032@cetys.mx',
  'juez033@cetys.mx',
  'juez034@cetys.mx',
  'juez035@cetys.mx',
  'juez036@cetys.mx',
  'juez037@cetys.mx',
  'juez038@cetys.mx',
  'juez039@cetys.mx',
  'juez040@cetys.mx',
  'juez041@cetys.mx',
  'juez042@cetys.mx',
  'juez043@cetys.mx',
  'juez044@cetys.mx',
  'juez045@cetys.mx',
  'juez046@cetys.mx',
  'juez047@cetys.mx',
  'juez048@cetys.mx',
  'juez049@cetys.mx',
  'juez050@cetys.mx',
  'juez051@cetys.mx',
  'juez052@cetys.mx',
  'juez053@cetys.mx',
  'juez054@cetys.mx',
  'juez055@cetys.mx',
  'juez056@cetys.mx',
  'juez057@cetys.mx',
  'juez058@cetys.mx',
  'juez059@cetys.mx',
  'juez060@cetys.mx',
  'juez061@cetys.mx',
  'juez062@cetys.mx',
  'juez063@cetys.mx',
  'juez064@cetys.mx',
  'juez065@cetys.mx',
  'juez066@cetys.mx',
  'juez067@cetys.mx',
  'juez068@cetys.mx',
  'juez069@cetys.mx',
  'juez070@cetys.mx',
  'juez071@cetys.mx',
  'juez072@cetys.mx',
  'juez073@cetys.mx',
  'juez074@cetys.mx',
  'juez075@cetys.mx',
  'juez076@cetys.mx',
  'juez077@cetys.mx',
  'juez078@cetys.mx',
  'juez079@cetys.mx',
  'juez080@cetys.mx',
  'juez081@cetys.mx',
  'juez082@cetys.mx',
  'juez083@cetys.mx',
  'juez084@cetys.mx',
  'juez085@cetys.mx',
  'juez086@cetys.mx',
  'juez087@cetys.mx',
  'juez088@cetys.mx',
  'juez089@cetys.mx',
  'juez090@cetys.mx',
  'juez091@cetys.mx',
  'juez092@cetys.mx',
  'juez093@cetys.mx',
  'juez094@cetys.mx',
  'juez095@cetys.mx',
  'juez096@cetys.mx',
  'juez097@cetys.mx',
  'juez098@cetys.mx',
  'juez099@cetys.mx',
  'juez100@cetys.mx',
  'juez101@cetys.mx',
  'juez102@cetys.mx',
  'juez103@cetys.mx',
  'juez104@cetys.mx',
  'juez105@cetys.mx',
  'juez106@cetys.mx',
  'juez107@cetys.mx',
  'juez108@cetys.mx',
  'juez109@cetys.mx',
  'juez110@cetys.mx',
  'juez111@cetys.mx',
  'juez112@cetys.mx',
  'juez113@cetys.mx',
  'juez114@cetys.mx',
  'juez115@cetys.mx',
  'juez116@cetys.mx',
  'juez117@cetys.mx',
  'juez118@cetys.mx',
  'juez119@cetys.mx',
  'juez120@cetys.mx',
  'juez121@cetys.mx',
  'juez122@cetys.mx',
  'juez123@cetys.mx',
  'juez124@cetys.mx',
  'juez125@cetys.mx',
  'juez126@cetys.mx',
  'juez127@cetys.mx',
  'juez128@cetys.mx',
  'juez129@cetys.mx',
  'juez130@cetys.mx',
  'juez131@cetys.mx',
  'juez132@cetys.mx',
  'juez133@cetys.mx',
  'juez134@cetys.mx',
  'juez135@cetys.mx',
  'juez136@cetys.mx',
  'juez137@cetys.mx',
  'juez138@cetys.mx',
  'juez139@cetys.mx',
  'juez140@cetys.mx',
  'juez141@cetys.mx',
  'juez142@cetys.mx',
  'juez143@cetys.mx',
  'juez144@cetys.mx',
  'juez145@cetys.mx',
  'juez146@cetys.mx',
  'juez147@cetys.mx',
  'juez148@cetys.mx',
  'juez149@cetys.mx',
  'juez150@cetys.mx',
];